<template>
  <v-card>
    <v-card-title class="justify-center">
      <span class="headline">{{ this.title }}</span>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="isFormValid">
        <v-row no-gutters>
          <v-col md="10" offset-md="1">
            <v-row>
              <v-col md="12">
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="ap">Akun</label>
                  </div>
                  <v-combobox
                    id="ap"
                    v-model="input.id_account"
                    :items="resource.account"
                    item-text="displayName"
                    item-value="_id"
                    dense
                    class="detail-input__input-text"
                    :isLoading="isLoading"
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="name">Nama Akun Pembantu</label>
                  </div>
                  <v-text-field
                    id="name"
                    v-model="input.name"
                    dense
                    class="detail-input__input-text"
                    :isLoading="isLoading"
                    :rules="[v => !!v || 'Harus di Isi.']"
                  />
                </div>
              </v-col>

              <v-col cols="12">
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" width="100" text large @click="onCloseButtonClick()">
                    Kembali
                  </v-btn>
                  <v-btn color="primary" width="100" @click="saveChartAccount()" large>
                    Simpan
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2'
import Constant from '@/const'

import constantMixin from '@/constantMixin'

const _ = require('lodash')
const axios = require('axios')

export default {
  name: 'ChartAccountHelperComponent',
  mixins: [constantMixin],
  props: {
    account: Object
  },
  data: () => ({
    title: 'Tambah Akun Pembantu',
    isFormValid: false,
    isLoading: false,
    dialog: false,
    tagSearch: null,
    accountParent: '',
    input: {
      id_account: '',
      name: '',
      nominal: 0,
      timestamps: {
        created_by: ''
      }
    },
    resource: {
      account: []
    }
  }),
  watch: {},
  mounted() {
    this.$nextTick(() => {
      this.getChartAccount()

      this.input.timestamps.created_by = this.userLoggedIn.id
    })
  },

  methods: {
    getChartAccount() {
      try {
        this.isLoading = true

        this.runApiCalls(() => {
          axios.get(Constant.apiUrl.concat('/accounting/chartaccount')).then(response => {
            if (response.status == '200') {
              this.resource.account = response.data.data
                .map(d => {
                  return {
                    ...d,
                    splitCode: d.code.split('.'),
                    displayName: `${d.code} - ${d.name}`
                  }
                })
                .filter(v => v.splitCode[2] != 0)
            }
            this.isLoading = false
          })
        }).catch(error => {
          // console.log(error)
        })
      } catch (error) {
        this.isLoading = false
      }
    },
    saveChartAccount() {
      try {
        axios
          .post(Constant.apiUrl.concat('/accounting/chartaccounthelper'), this.input)
          .then(response => {
            if (response.status === 200) {
              this.onCloseButtonClick()
              this.swalToast('success', 'Data Akun Berhasil Disimpan')
            }
          })
          .catch(error => {
            if (error.response) {
              const message = error.response.data.pesan
              Swal.fire('Terjadi Kesalahan', message, 'error')
            } else if (error.request) {
              Swal.fire('Terjadi Kesalahan', 'Tidak dapat menyambung ke server', 'error')
            } else {
              // eslint-disable-next-line
              // console.log('Something happened in the middle of request')
            }
          })
      } catch (error) {
        // console.log(error)
        Swal.fire({
          icon: 'error',
          title: 'Terjadi Kesalahan',
          text: 'Gagal menyimpan account!'
        })
      }
    },
    onCloseButtonClick() {
      this.$emit('close-form')
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-radio {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.detail-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.detail-input {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  &__label {
    flex: 1;
    display: flex;
  }

  &__input-text {
    margin-left: 10px;
    max-width: 60%;
  }
}
input:placeholder-shown {
  font-size: 0.5em;
}
</style>
