<template>
  <v-card>
    <v-card-title class="justify-center">
      <span class="headline">{{ this.title }}</span>
    </v-card-title>
    <v-card-text>
      <v-form class="pt-3" ref="form" v-model="isFormValid">
        <v-row no-gutters>
          <v-col>
            <v-row class="justify-center">
              <v-col cols="3">
                <div class="pl-5">
                  <div class="detail-input">
                    <v-text-field
                      id="code"
                      readonly
                      :disabled="id_journal"
                      v-model="input.code"
                      dense
                      label="Kode Jurnal"
                      placeholder="[Auto Generate]"
                      class="detail-input__input-text"
                      required
                      :isLoading="isLoadingCode"
                      :rules="[v => !!v || 'Harus di Isi.']"
                    />
                  </div>
                </div>
              </v-col>
              <v-col cols="2">
                <div class="detail-input">
                  <v-menu
                    ref="menu"
                    disabled
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="input.journal_date"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        label="Tanggal Jurnal"
                        placeholder="Tanggal pembuatan"
                        class="detail-input__input-text"
                        required
                        :disabled="id_journal"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :value="journalDateFormat"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @click:date="
                        $refs.menu.save(input.journal_date)
                        menu = false
                      "
                      v-model="input.journal_date"
                      locale="id-ID"
                      scrollable
                      no-title
                      required
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false"> Kembali </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>
              </v-col>
              <v-col cols="2">
                <div class="detail-input">
                  <v-select
                    v-if="!id_journal"
                    id="type"
                    v-model="input.journal_type"
                    :items="resource.type"
                    item-text="text"
                    item-value="value"
                    dense
                    label="Tipe Jurnal"
                    class="detail-input__input-text"
                    required
                    @change="getJournalCode()"
                    :isLoading="isLoading"
                    :rules="[v => !!v || 'Harus di Isi.']"
                  />
                  <v-text-field
                    v-else
                    id="typeText"
                    readonly
                    disabled
                    v-model="input.journal_type"
                    dense
                    label="Tipe Jurnal"
                    class="detail-input__input-text"
                  />
                </div>
              </v-col>
              <v-col cols="3">
                <div class="detail-input">
                  <v-textarea
                    id="description"
                    v-model="input.description"
                    :disabled="!isDescription"
                    label="Deskripsi"
                    class="detail-input__input-text pt-10"
                    required
                    :isLoading="isLoading"
                    no-resize
                    clearable
                    height="30px"
                  />
                </div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-progress-linear v-if="isLoading" indeterminate color="primary"></v-progress-linear>
            <v-row v-if="!id_journal" class="justify-center pt-2">
              <v-col cols="3">
                <v-autocomplete
                  id="account"
                  label="Akun"
                  v-model="selectedAccount"
                  placeholder="Pilih Akun"
                  :items="resource.account"
                  :loading="isLoadingAccount"
                  return-object
                  clearable
                  class=""
                  :item-text="
                    item => `${item.code} - ${item.name} ${item.id_accountHelper ? '(Pembantu)' : ''}`
                  "
                  @change="checkAddItem()"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>{{
                        `${item.code} - ${item.name} ${item.id_accountHelper ? '(Pembantu)' : ''}`
                      }}</v-list-item-title>
                      <v-list-item-subtitle> Saldo : {{ getCurrency(item.saldo) }} </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="3">
                <v-select
                  id="isDebit"
                  v-model="journalItems.isDebit"
                  :items="resource.itemType"
                  item-text="text"
                  item-value="value"
                  dense
                  label="Jenis"
                  class="pt-4 detail-input__input-text"
                />
              </v-col>
              <v-col cols="3">
                <v-currency-field
                  id="nominal"
                  label="Nominal"
                  class="pt-4 detail-input__input-text"
                  @keypress="onlyNumber"
                  @keyup="checkAddItem()"
                  @keydown.enter="addItem()"
                  v-model="journalItems.nominal"
                  dense
                  prefix="Rp."
                  placeholder="Masukkan angka"
                />
              </v-col>
              <v-col cols="1">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="!id_journal"
                      v-bind="attrs"
                      v-on="on"
                      class="mt-3"
                      height="30"
                      width="100"
                      @click="addItem()"
                      :color="isAllowItemAdd ? 'primary' : 'error'"
                    >
                      Tambah
                    </v-btn>
                  </template>
                  <span v-if="!isAllowItemAdd">Akun dan Jumlah harus diisi terlebih dahulu</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-divider inset></v-divider>
            <v-row class="pt-5">
              <v-col cols="6">
                <label class="pl-16 text-h5">Debit</label>
                <v-simple-table class="simple-table-debit" dense height="450px">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="font-weight-bold">Akun</th>
                        <th class="font-weight-bold">Nominal</th>
                        <th class="font-weight-bold">Saldo</th>

                        <th class="text-left"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in table.debit" :key="index">
                        <td class="font-weight-black">{{ item.accountName }}</td>
                        <td class="font-weight-black">{{ getCurrency(item.nominal) }}</td>
                        <td class="font-weight-black">{{ getCurrency(item.saldo) }}</td>
                        <td>
                          <v-icon v-if="!id_journal" @click="removeItem(item, index)">mdi-close</v-icon>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
              <v-col cols="6">
                <label class="pl-16 text-h5">Kredit</label>
                <v-simple-table class="simple-table-credit" dense height="450px">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="font-weight-bold">Akun</th>
                        <th class="font-weight-bold">Nominal</th>
                        <th class="font-weight-bold">Saldo</th>

                        <th class="text-left"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in table.credit" :key="index">
                        <td class="font-weight-black">{{ item.accountName }}</td>
                        <td class="font-weight-black">{{ getCurrency(item.nominal) }}</td>
                        <td class="font-weight-black">{{ getCurrency(item.saldo) }}</td>
                        <td>
                          <v-icon v-if="!id_journal" @click="removeItem(item, index)">mdi-close</v-icon>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>

            <v-row>
              <v-col offset="6" cols="3">
                <label class="font-weight-bold">{{ `Jumlah Debit ${getCurrency(totalDebit)}` }}</label>
              </v-col>
              <v-col cols="3">
                <label class="font-weight-bold">{{ `Jumlah Kredit ${getCurrency(totalCredit)}` }}</label>
              </v-col>
            </v-row>

            <v-divider class="py-3"></v-divider>
            <v-row>
              <v-col cols="12">
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    :loading="isSaveLoading"
                    color="secondary"
                    width="100"
                    text
                    large
                    @click="onCloseButtonClick()"
                  >
                    Kembali
                  </v-btn>
                  <v-btn
                    v-if="!id_journal"
                    color="primary"
                    :disabled="!isBalance || isEmpty"
                    width="130"
                    @click="saveJournal()"
                    large
                  >
                    {{ isBalance ? 'Simpan' : 'Tidak Balance' }}
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2'
import Constant from '@/const'

import constantMixin from '@/constantMixin'

const _ = require('lodash')
const axios = require('axios')
const moment = require('moment')

export default {
  name: 'JournalComponent',
  mixins: [constantMixin],
  props: {
    id_journal: String
  },
  data: () => ({
    menu: false,
    title: 'Buat Jurnal Baru',
    isFormValid: false,
    isLoading: false,
    isLoadingCode: false,
    isSaveLoading: false,
    isLoadingAccount: false,
    isDescription: true,
    dialog: false,
    tagSearch: null,
    journalDateFormat: '',
    selectedAccount: null,
    totalDebit: 0,
    totalCredit: 0,
    isBalance: true,
    isAllowItemAdd: false,
    isEmpty: true,
    journalItems: {
      accountName: '',
      id_accountHelper: null,
      id_account: null,
      isDebit: false,
      nominal: 0
    },
    input: {
      code: '',
      journal_date: '',
      journal_type: 'general',
      description: '',
      isBackdate: false,
      document: {
        path: '',
        name: ''
      },
      status: 'waiting',
      items: [],
      timestamps: {
        created_by: ''
      }
    },
    table: {
      debit: [],
      credit: []
    },
    resource: {
      account: [],
      itemType: [
        { text: 'Kredit', value: false },
        { text: 'Debit', value: true }
      ],
      type: [
        { text: 'UMUM', value: 'general' },
        { text: 'PENYESUAIAN', value: 'adjustment' }
      ]
    }
  }),

  watch: {
    'input.journal_date'(val) {
      this.journalDateFormat = this.datePickerFormat(val)
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.id_journal) {
        this.title = 'Detail Jurnal'
        this.isDescription = false
        this.getJournal()
      } else {
        this.input.journal_date = moment().format()
        this.input.journal_type = 'general'
        this.input.status = 'posted'

        this.getListAccount()
        this.getJournalCode()
      }

      this.input.timestamps.created_by = this.userLoggedIn.id
    })
  },

  methods: {
    onEnter(e) {
      if (e.keyCode === 13) {
      }
    },
    checkSaveJournal() {
      this.totalCredit = _.sumBy(this.table.credit, 'nominal')
      this.totalDebit = _.sumBy(this.table.debit, 'nominal')

      if (this.totalDebit != this.totalCredit) this.isBalance = false
      else this.isBalance = true

      if (this.totalCredit && this.totalDebit) this.isEmpty = false
      else this.isEmpty = true
    },
    checkAddItem() {
      if (this.journalItems.nominal <= 0 || !this.selectedAccount) this.isAllowItemAdd = false
      else this.isAllowItemAdd = true
    },
    removeItem(item, index) {
      if (item.isDebit) this.table.debit.splice(index, 1)
      else this.table.credit.splice(index, 1)

      const inputIndex = this.input.items.findIndex(
        v => v.id_accountHelper == item.id_accountHelper && v.id_account == item.id_account
      )
      if (inputIndex >= 0) this.input.items.splice(inputIndex, 1)
      // console.log(this.input.items)
      this.checkSaveJournal()
    },
    addItem() {
      try {
        if (this.journalItems.nominal > 0 || this.selectedAccount) {
          let item = {
            id_accountHelper: this.selectedAccount.id_accountHelper,
            id_account: this.selectedAccount.id_accountHelper ? null : this.selectedAccount.id_account,
            accountName: `${this.selectedAccount.code} - ${this.selectedAccount.name} ${
              this.selectedAccount.id_accountHelper ? '(Pembantu)' : ''
            }`,
            isDebit: this.journalItems.isDebit,
            nominal: this.journalItems.nominal,
            saldo: this.selectedAccount.saldo
          }

          // if (item.isDebit != this.selectedAccount.isDebit)
          //   if (this.selectedAccount.saldo < item.nominal) return this.swalToast('error', 'Saldo tidak cukup')

          this.input.items.push(item)

          if (this.journalItems.isDebit) this.table.debit.push(item)
          else this.table.credit.push(item)

          this.selectedAccount = null
          this.journalItems.accountName = ''
          this.journalItems.id_accountHelper = null
          this.journalItems.id_account = null
          this.journalItems.isDebit = false
          this.journalItems.nominal = 0
          // console.log(this.input.items)

          this.checkSaveJournal()
        }
      } catch (error) {}
    },

    getJournalCode() {
      try {
        this.isLoadingCode = true

        this.runApiCalls(() => {
          axios
            .get(Constant.apiUrl.concat('/accounting/journal/generate/', this.input.journal_type))
            .then(response => {
              const { code } = response.data.data

              if (code) this.input.code = code
              this.isLoadingCode = false
            })
        })
      } catch (error) {
        this.isLoadingCode = false
      }
    },
    getListAccount() {
      try {
        this.isLoadingAccount = true

        this.runApiCalls(() => {
          axios.get(Constant.apiUrl.concat('/accounting/chartaccount/listaccount')).then(response => {
            const { data } = response.data

            if (data) this.resource.account = data
            this.isLoadingAccount = false
          })
        })
      } catch (error) {
        this.isLoadingAccount = false
      }
    },
    getJournal() {
      try {
        this.isLoading = true

        this.runApiCalls(() => {
          axios.get(Constant.apiUrl.concat('/accounting/journal/', this.id_journal)).then(response => {
            const journal = response.data.data

            this.input.code = _.get(journal, 'code', '')
            this.input.journal_date = _.get(journal, 'journal_date', '')

            if (_.get(journal, 'journal_type', '') == 'general') this.input.journal_type = 'UMUM'
            else if (_.get(journal, 'journal_type', '') == 'adjustment')
              this.input.journal_type = 'PENYESUAIAN'
            else if (_.get(journal, 'journal_type', '') == 'auto') this.input.journal_type = 'OTOMATIS'

            this.input.description = _.get(journal, 'description', '')
            this.input.status = _.get(journal, 'status', '')
            this.input.items = _.get(journal, 'items', [])

            for (const item of this.input.items) {
              const code = item.id_accountHelper
                ? item.id_accountHelper.id_account.code
                : item.id_account.code
              const name = item.id_accountHelper ? item.id_accountHelper.name : item.id_account.name
              const saldo = item.id_accountHelper ? item.id_accountHelper.nominal : item.id_account.nominal

              const addedItem = {
                id_accountHelper: item?.id_accountHelper?._id,
                id_account: item?.id_account?._id,
                accountName: `${code} - ${name}`,
                isDebit: item.isDebit,
                nominal: item.nominal,
                saldo
              }

              if (item.isDebit) this.table.debit.push(addedItem)
              else this.table.credit.push(addedItem)
            }
            this.totalCredit = _.sumBy(this.table.credit, 'nominal')
            this.totalDebit = _.sumBy(this.table.debit, 'nominal')

            this.isLoading = false
          })
        })
      } catch (error) {
        this.isLoading = false
      }
    },
    saveJournal() {
      try {
        this.$refs.form.validate()
        if (this.isFormValid) {
          this.swalLoading()
          axios
            .post(Constant.apiUrl.concat('/accounting/journal'), this.input)
            .then(response => {
              if (response.status === 200) {
                this.onCloseButtonClick()
                this.swalToast('sucess', 'Jurnal Berhasil Dibuat!')
              }
            })
            .catch(error => {
              this.swalToast('error', 'Gagal menyimpan jurnal')
              if (error.response) {
                const message = error.response.data.pesan
                Swal.fire('Terjadi Kesalahan', message, 'error')
              } else if (error.request) {
                Swal.fire('Terjadi Kesalahan', 'Tidak dapat menyambung ke server', 'error')
              } else {
                // eslint-disable-next-line
                // console.log('Something happened in the middle of request')
              }
            })
        }
      } catch (error) {
        // console.log(error)
        Swal.fire({
          icon: 'error',
          title: 'Terjadi Kesalahan',
          text: 'Gagal menyimpan journal!'
        })
      }
    },
    onCloseButtonClick() {
      this.$emit('close-form')
    }
  }
}
</script>

<style lang="scss" scoped>
.simple-table-debit {
  background: rgba($color: #a5d6a7, $alpha: 0.2);
}
.simple-table-credit {
  background: rgba($color: #e57373, $alpha: 0.2);
}
.detail-radio {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.detail-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.detail-input {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  &__label {
    flex: 1;
    display: flex;
    // color: #222;
  }

  &__input-text {
    margin-left: 10px;
    max-width: 80%;
    ::v-deep .v-label {
      font-weight: 400;
      font-size: 18px;
      // color: black;
    }
  }
}
input:placeholder-shown {
  font-size: 0.5em;
}
</style>
