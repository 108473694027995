<template>
  <v-card>
    <v-card-title class="justify-center">
      <span class="headline">{{ this.title }}</span>
      <v-btn
        :disabled="isUploading"
        absolute
        right
        fab
        @click="onCloseButtonClick()"
        small
        text
        color="primary"
      >
        <v-icon dark>
          mdi-close-circle
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-subtitle class="justify-center">
      <br />

      <h4>Jenis file : (.pdf)</h4>
      <h4 class="red--text">ukuran setiap file max. 10 MB</h4>

      <h4 class="blink">
        Jika memiliki beberapa gambar atau dokumen pada satu kategori, bisa digabungkan kedalam PDF terlebih
        dahulu.
      </h4>
    </v-card-subtitle>
    <v-card-text>
      <div>
        <v-progress-linear v-if="loading" indeterminate class="upload-bar" color="green"></v-progress-linear>
      </div>
      <v-container>
        <v-row no-gutters>
          <v-col md="10" offset-md="1">
            <template v-for="(item, fileIndex) in templates">
              <v-row :key="`${fileIndex}-${item.isEnable}`" v-if="item.isEnable">
                <v-col md="8" align-self="center">
                  <label for="member">
                    <h3>
                      <b>{{ item.title }}</b>
                    </h3>
                  </label>
                </v-col>
                <v-col md="4" align-self="center" v-if="item.title.toLowerCase() != 'bukti transfer'">
                  <v-flex xs6>
                    <input
                      type="file"
                      id="files"
                      ref="files"
                      accept="application/pdf"
                      v-on:change="handleFilesUpload(fileIndex)"
                      v-show="false"
                    />

                    <v-btn
                      v-if="item.files.length == 0"
                      :disabled="isUploading || loading"
                      depressed
                      block
                      class="upload-button mt-3 mb-2"
                      @click="addFiles(fileIndex)"
                    >
                      Pilih File</v-btn
                    >
                  </v-flex>
                </v-col>
              </v-row>
              <br :key="'div3-' + fileIndex" />
              <v-row :key="'documentFailed-' + fileIndex">
                <template v-for="(docItem, documentIndex) in item.failedFiles">
                  <v-col align-self="center" :key="'col1-' + documentIndex" md="8">
                    <v-flex md9 class="mx-2">
                      <p class="text-md-left ma-0">
                        File {{ docItem.file.name }} gagal diunggah karena
                        <span class="yellow--text ">{{ docItem.message }}</span>
                      </p>
                    </v-flex>
                  </v-col>
                  <v-col :key="'col3-' + documentIndex" md="4">
                    <v-flex md1>
                      <v-btn
                        small
                        block
                        color="error"
                        depressed
                        @click="removeWarning(fileIndex, documentIndex)"
                      >
                        Hapus
                      </v-btn>
                    </v-flex>
                  </v-col>
                </template>
              </v-row>
              <v-row :key="'document-' + fileIndex">
                <template v-for="(docItem, documentIndex) in item.files">
                  <v-col align-self="center" :key="'col1-' + documentIndex" md="6">
                    <v-flex md7>
                      <p class="text-md-left">
                        {{ docItem.name || docItem.originalname }}
                      </p>
                    </v-flex>
                  </v-col>
                  <v-row align="center" :key="'row1-' + documentIndex" md="6">
                    <v-col md="6">
                      <v-btn
                        small
                        block
                        color="primary"
                        :disabled="docItem.isDelete"
                        depressed
                        @click="previewFile(docItem)"
                        :loading="item.isUploading"
                      >
                        {{ docItem.isDelete ? 'Akan diHapus' : 'Tampilkan' }}
                      </v-btn>
                    </v-col>
                    <v-col md="6" v-if="item.title.toLowerCase() != 'bukti transfer'">
                      <v-btn
                        v-if="!docItem.isExist"
                        small
                        block
                        color="error"
                        depressed
                        :loading="item.isUploading"
                        @click="removeFiles(fileIndex, documentIndex)"
                      >
                        Batal
                      </v-btn>
                      <v-btn
                        v-else
                        small
                        block
                        color="error"
                        depressed
                        :loading="item.isUploading"
                        @click="deleteFiles(fileIndex, documentIndex)"
                      >
                        {{ item.isDelete ? 'Batal Hapus' : 'Hapus' }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>
              </v-row>
              <v-flex :key="'flex-' + fileIndex">
                <v-progress-linear
                  class="upload-bar"
                  :value="item.progress"
                  v-if="item.isUploading"
                ></v-progress-linear>
                <h4 class="text-md-left" v-if="item.isUploading">{{ item.progress }} %</h4>
              </v-flex>
              <br :key="'div1-' + fileIndex" v-if="item.isEnable" />
              <v-divider :key="'divider-' + fileIndex" v-if="item.isEnable"></v-divider>
              <br :key="'div2-' + fileIndex" v-if="item.isEnable" />
            </template>
          </v-col>
        </v-row>
        <br />
        <v-row no-gutters>
          <v-col offset="1" cols="6">
            <label>
              <h3>
                <b>Jumlah Pengajuan </b>
              </h3>
            </label>
          </v-col>
          <v-col cols="3">
            <v-currency-field
              class="currency-field"
              :readonly="isAdmin"
              @keypress="onlyNumber"
              id="claim"
              v-model="claim"
              prefix="Rp."
              dense
              placeholder="Pengajuan Santunan"
              :loading="loading"
            />
          </v-col>
          <v-col></v-col>
        </v-row>
        <v-row no-gutters>
          <v-col offset="1" cols="6">
            <label>
              <h3>
                <b>Tanggal Kematian </b>
              </h3>
            </label>
          </v-col>
          <v-col cols="3">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="passedDate"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  prepend-icon="mdi-calendar"
                  id="passedDate"
                  dense
                  placeholder="Masukkan Tanggal Kematian"
                  required
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :value="passedDateFormattedMomentjs"
                ></v-text-field>
              </template>
              <v-date-picker
                @click:date="
                  $refs.menu.save(passedDateDisplay)
                  menu = false
                "
                v-model="passedDateDisplay"
                locale="id-ID"
                no-title
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col></v-col>
          <br />
        </v-row>
        <v-row no-gutters>
          <v-col offset="1" cols="6">
            <label>
              <h3>
                <b>Alasan Santunan </b>
              </h3>
            </label>
          </v-col>
          <v-col cols="3">
            <v-textarea
              v-model="cause"
              dense
              placeholder="Isi dengan alasan kematian atau penyebab kebakaran sebagai alasan pengajuan santunan"
              :loading="loading"
            ></v-textarea>
            <!-- <v-currency-field
              class="currency-field"
              :readonly="isAdmin"
              @keypress="onlyNumber"
              id="claim"
              v-model="claim"
              prefix="Rp."
              dense
              :loading="loading"
            /> -->
          </v-col>
          <v-col></v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="secondary" width="100" text :loading="isUploading" large @click="onCloseButtonClick()">
        Kembali
      </v-btn>
      <v-btn
        :disabled="!passedDate || !claim"
        :color="'primary'"
        width="100"
        :loading="isUploading"
        @click="updateClaimNominal()"
        large
      >
        Simpan
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
/* eslint-disable no-unused-vars */
import Swal from 'sweetalert2'
import Constant from '@/const'

import ConstantMixin from '@/constantMixin'
// import { io } from 'socket.io-client'

const _ = require('lodash')
const axios = require('axios')
const moment = require('moment')
// const socket = io(process.env.VUE_APP_API_URL)

export default {
  mixins: [ConstantMixin],
  props: {
    id_subscription: String,
    id_company: String,
    isSave: Boolean
  },
  data: () => ({
    title: 'Pengajuan Santunan',
    loading: false,
    isUploading: false,
    isPaid: false,
    progress: 0,
    claim: 0,
    cause: '',
    menu: false,
    passedDate: null,
    passedDateDisplay: null,

    documents: [],
    templates: [
      {
        title: 'Surat Permohonan Santunan',
        isEnable: true,
        isUploading: false,
        progress: 0,
        files: [],
        failedFiles: []
      },
      {
        title: 'Surat Keterangan Kematian',
        isEnable: true,
        isUploading: false,
        progress: 0,
        files: [],
        failedFiles: []
      },
      {
        title: 'Kartu Tanda Penduduk dan Kartu Keluarga',
        isEnable: true,
        isUploading: false,
        progress: 0,
        files: [],
        failedFiles: []
      },
      {
        title: 'Akad Pembiayaan',
        isEnable: true,
        isUploading: false,
        progress: 0,
        files: [],
        failedFiles: []
      },
      {
        title: 'Riwayat Angsuran Pembiayaan',
        isEnable: true,
        isUploading: false,
        progress: 0,
        files: [],
        failedFiles: []
      },
      {
        title: 'Bukti Transfer',
        isEnable: true,
        files: [],
        failedFiles: []
      }
    ]
  }),
  computed: {
    passedDateFormattedMomentjs() {
      return this.passedDate
        ? moment(this.passedDate)
            .locale('id')
            .format('D MMMM YYYY')
        : ''
    }
  },
  // created() {
  //   this.$socket.client.off('claim')
  //   this.$socket.client.on('claim', data => {
  //     this.getSubscription()
  //   })
  // },
  mounted() {
    this.getSubscription()
  },
  methods: {
    updateClaimNominal() {
      try {
        this.loading = true
        if (this.claim)
          axios
            .put(
              Constant.apiUrl.concat(
                '/subscription/claim/',
                this.id_subscription,
                '?claim=',
                this.claim,
                '&date=',
                moment(this.passedDate).format('L'),
                `&cause=`,
                this.cause
              )
            )
            .then(response => {
              if (response.status === 200) {
                this.getSubscription()

                this.$socket.client.emit('claim:update', {
                  company: this.$store.getters.userLoggedIn.company.name,
                  staff: this.$store.getters.userLoggedIn
                })
                Swal.fire(
                  'Pengajuan Santunan berhasil',
                  'Pengajuan Santunan Senilai : ' + this.getCurrency(this.claim),
                  'success'
                )
              } else {
                Swal.fire({
                  icon: 'error',
                  title: 'Terjadi Kesalahan',
                  text: 'Detail Error tidak diketahui'
                })
              }
              this.loading = false
            })
            .catch(error => {
              this.loading = false
              this.showErrorAxios(error)
            })
        else Swal.fire('Terjadi Kesalahan', 'Nominal pengajuan santunan belum diisi!', 'warning')
      } catch (error) {
        this.loading = false
        this.showErrorAxios(error)
      }
    },
    getSubscription() {
      try {
        this.loading = true
        axios
          .get(Constant.apiUrl.concat('/subscription/', this.id_subscription))
          .then(response => {
            const subs = response.data.data

            this.documents = []
            this.claim = subs.claim || 0
            this.cause = subs.claim_cause
            if (subs.passed_date) this.passedDate = subs.passed_date

            // if (['102', '202'].indexOf(subs.product_code) == -1) this.templates[3].isEnable = false

            for (let i = 0; i < this.templates.length; i++) {
              const template = this.templates[i]
              const document = subs.claim_document.find(v => v.name == template.title.toLowerCase())

              if (template.title.toLowerCase() == 'bukti transfer') {
                if (document) {
                  if (document.files.length == 0) {
                    this.templates[i].isEnable = false
                    continue
                  }
                } else {
                  this.templates[i].isEnable = false
                  continue
                }
              }

              if (document) {
                this.templates[i].files = document.files
              }
            }
            this.loading = false
          })
          .catch(error => {
            this.showErrorAxios(error)
            this.loading = false
          })
      } catch (error) {
        this.loading = false
      }
    },
    handleFilesUpload(index) {
      try {
        const filesUploaded = this.$refs.files[index].files
        for (let i = 0; i < filesUploaded.length; i++) {
          if (!this.verify(filesUploaded[i])) {
            if (this.templates[index].files.length > 0) this.templates[index].files = []
            this.templates[index].files.push(filesUploaded[i])
            this.submitFiles(index)
          } else {
            if (this.templates[index].failedFiles.length > 0) this.templates[index].failedFiles = []
            this.templates[index].failedFiles.push({
              file: filesUploaded[i],
              message: this.verify(filesUploaded[i])
            })
          }
        }
        this.$refs.files[index].value = ''
      } catch (error) {
        Swal.fire('Gagal', error, 'error')
      }
    },
    async submitFiles(index) {
      try {
        this.templates[index].isUploading = true
        this.isUploading = true
        const formData = new FormData()
        this.templates[index].files.forEach(file => {
          formData.append(`files-${index}`, file.isExist ? JSON.stringify(file) : file)
        })
        formData.append('identifier', this.id_subscription)
        formData.append('tag', 'claimDocument')

        await axios
          .post(Constant.apiUrl.concat('/subscription/upload'), formData, {
            onUploadProgress: progressEvent => {
              this.templates[index].progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            },
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(response => {
            if (response.status === 200) {
              this.getSubscription()

              this.$socket.client.emit('claim:update', {
                company: this.$store.getters.userLoggedIn.company.name,
                staff: this.$store.getters.userLoggedIn
              })

              // Swal.fire('Berhasil!', 'Dokumen berhasil di perbarui', 'success')
              this.templates[index].isUploading = false
              this.isUploading = false
            }
          })
          .catch(error => {
            this.showErrorAxios(error)
            this.templates[index].isUploading = false
            this.isUploading = false
          })
      } catch (error) {
        Swal.fire('Gagal', error, 'error')
      }
    },
    addFiles(index) {
      this.$refs.files[index].click()
    },
    previewFile(file) {
      let url = ''
      if (typeof file === 'object') url = Constant.apiUrl.concat(`/${file.path}`)
      else url = URL.createObjectURL(file)
      window.open(url)
    },
    removeFiles(index, fileIndex) {
      this.templates[index].files.splice(fileIndex, 1)
    },
    deleteFiles(index, fileIndex) {
      this.templates[index].files[fileIndex].isDelete = !this.templates[index].files[fileIndex].isDelete
      this.submitFiles(index)
    },
    removeWarning(index, fileIndex) {
      this.templates[index].failedFiles.splice(fileIndex, 1)
    },
    verify(file) {
      const maxSize = 10000000
      const allowedTypes = ['application/pdf']
      if (file.size > maxSize) {
        return 'Maksimum Ukuran file 10 MB'
      }

      const ext = file.name.split('.')

      if (!allowedTypes.includes(file.type)) {
        return `Tipe file .${ext[1]} tidak diperbolehkan`
      }

      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
.currency-field {
  max-width: 100%;
}
.detail-radio {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.detail-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.label-header {
  color: #222;
}

.detail-input {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  &__label {
    flex: 1;
    display: flex;
    color: #222;
  }

  &__input-text {
    margin-left: 10px;
    max-width: 60%;
  }
}

input:placeholder-shown {
  font-size: 0.5em;
}
.blink {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
</style>
