<template>
  <v-card>
    <v-card-title class="justify-center">
      <span class="headline">{{ this.title }}</span>
      <v-btn absolute right fab @click="onCloseButtonClick()" small text color="primary">
        <v-icon dark>
          mdi-close-circle
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row no-gutters>
          <v-col md="10" offset-md="2">
            <v-row>
              <v-col md="10">
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="name">Tipe BMT</label>
                  </div>
                  <v-radio-group id="type" v-model="isBranch" :loading="loading" mandatory dense row>
                    <v-radio id="radHQ" label="Pusat" value="0"></v-radio>
                    <v-radio id="radBranch" label="Cabang" value="1"></v-radio>
                  </v-radio-group>
                </div>
                <div v-if="!input.isHeadquarters" class="detail-input">
                  <div class="detail-input__label">
                    <label for="name">BMT Pusat</label>
                  </div>
                  <v-combobox
                    id="headquarters"
                    v-model="selectedCompany"
                    dense
                    placeholder="Pilih Kantor Pusat"
                    class="detail-input__input-autocomplete"
                    :items="resource.company"
                    :loading="resource.loadingCompany"
                    item-text="name"
                    return-object
                    clearable
                  ></v-combobox>
                </div>
                <div v-if="!input.isHeadquarters" class="detail-input">
                  <div class="detail-input__label">
                    <label for="name">Nama Cabang</label>
                  </div>
                  <v-text-field
                    id="branchName"
                    v-model="input.headquarters.description"
                    dense
                    placeholder="Masukkan Nama Cabang"
                    class="detail-input__input-text"
                    required
                    :loading="loading"
                  />
                </div>
                <div v-else class="detail-input">
                  <div class="detail-input__label">
                    <label for="name">Nama BMT</label>
                  </div>
                  <v-text-field
                    id="name"
                    v-model="input.name"
                    dense
                    placeholder="Masukkan Nama BMT"
                    class="detail-input__input-text"
                    required
                    :loading="loading"
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="province">Provinsi</label>
                  </div>
                  <v-combobox
                    id="province"
                    v-model="selectedProvince"
                    dense
                    placeholder="Pilih Provinsi"
                    class="detail-input__input-autocomplete"
                    :items="resource.province"
                    :loading="resource.loadingProvince"
                    return-object
                    clearable
                  ></v-combobox>
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="regency">Kabupaten</label>
                  </div>
                  <v-combobox
                    id="regency"
                    :disabled="!selectedProvince"
                    v-model="selectedRegency"
                    dense
                    placeholder="Pilih Kabupaten"
                    class="detail-input__input-autocomplete"
                    :items="resource.regency"
                    :loading="resource.loadingRegency"
                    return-object
                    clearable
                  ></v-combobox>
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="district">Kecamatan</label>
                  </div>
                  <v-combobox
                    id="district"
                    :disabled="!selectedRegency"
                    v-model="selectedDistrict"
                    dense
                    placeholder="Pilih Kecamatan"
                    class="detail-input__input-autocomplete"
                    :items="resource.district"
                    :loading="resource.loadingDistrict"
                    return-object
                    clearable
                  ></v-combobox>
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="subdistrict">Kelurahan</label>
                  </div>
                  <v-combobox
                    id="subdistrict"
                    :disabled="!selectedDistrict"
                    v-model="selectedSubdistrict"
                    dense
                    placeholder="Pilih Kelurahan"
                    class="detail-input__input-autocomplete"
                    :items="resource.subdistrict"
                    :loading="resource.loadingSubdistrict"
                    return-object
                    clearable
                  ></v-combobox>
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="address">Alamat Lengkap</label>
                  </div>
                  <v-text-field
                    id="address"
                    v-model="input.address.description"
                    dense
                    placeholder="Masukkan Alamat Lengkap BMT"
                    class="detail-input__input-text"
                    required
                    :loading="loading"
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="email">E-Mail</label>
                  </div>
                  <v-text-field
                    id="email"
                    v-model="input.email"
                    dense
                    placeholder="Masukkan E-Mail"
                    class="detail-input__input-text"
                    required
                    :loading="loading"
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="phone">No Telepon</label>
                  </div>
                  <v-text-field
                    id="phone"
                    @keypress="onlyNumber"
                    v-model="input.phone"
                    dense
                    placeholder="Masukkan No Telepon"
                    class="detail-input__input-text"
                    required
                    :loading="loading"
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="bankName">Bank</label>
                  </div>
                  <v-text-field
                    id="bankName"
                    v-model="input.bank.name"
                    dense
                    placeholder="Masukan Nama Bank"
                    class="detail-input__input-text"
                    required
                    :loading="loading"
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="bankNumber">Nomor Rekening</label>
                  </div>
                  <v-text-field
                    id="bankNumber"
                    @keypress="onlyNumber"
                    v-model="input.bank.number"
                    dense
                    placeholder="Masukan Nomor Rekening Bank"
                    class="detail-input__input-text"
                    required
                    :loading="loading"
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="bankNumberName">Nama Rekening</label>
                  </div>
                  <v-text-field
                    id="bankNumberName"
                    v-model="input.bank.ownerName"
                    dense
                    placeholder="Nama pada Rekening"
                    class="detail-input__input-text"
                    required
                    :loading="loading"
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="npwp">NPWP</label>
                  </div>
                  <v-text-field
                    id="npwp"
                    :readonly="!input.isHeadquarters"
                    v-model="input.npwp"
                    dense
                    placeholder="NPWP"
                    class="detail-input__input-text"
                    required
                    :loading="loading"
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="isActive">Aktif</label>
                  </div>
                  <v-checkbox v-model="input.isActive" class="detail-input__input-text"></v-checkbox>
                  <v-spacer></v-spacer>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="secondary" width="100" text large @click="onCloseButtonClick()">
        Kembali
      </v-btn>
      <v-btn color="primary" width="100" @click="saveCompany()" large>
        Simpan
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2'
import Constant from '@/const'

import ConstantMixin from '@/constantMixin'

const _ = require('lodash')
const axios = require('axios')

export default {
  mixins: [ConstantMixin],
  props: {
    id_company: String
  },
  data: () => ({
    title: 'Daftar BMT Baru',
    loading: false,
    loadingCompany: true,
    dialog: false,
    dialogDelete: false,
    showPassword: false,
    activeRadio: '',
    isNew: true,
    isBranch: '0',
    companyName: '',
    input: {
      name: '',
      isHeadquarters: true,
      headquarters: {
        id_company: null,
        description: ''
      },
      address: {
        province: '',
        regency: '',
        district: '',
        subdistrict: '',
        description: ''
      },
      email: '',
      phone: '',
      isActive: true,
      bank: {
        name: '',
        number: '',
        ownerName: ''
      },
      npwp: '',
      timestamps: {
        created_by: ''
      }
    },
    selectedCompany: '',
    selectedProvince: '',
    selectedRegency: '',
    selectedDistrict: '',
    selectedSubdistrict: '',
    resource: {
      loadingCompany: true,
      loadingProvince: true,
      loadingRegency: false,
      loadingDistrict: false,
      loadingSubdistrict: false,
      company: [],
      province: [],
      regency: [],
      district: [],
      subdistrict: []
    }
  }),
  watch: {
    isBranch: function(val) {
      this.input.isHeadquarters = val == '0' ? true : false
      if (this.input.isHeadquarters) this.input.headquarters.id_company = null
    },
    selectedCompany: function(val) {
      if (val) {
        this.input.headquarters.id_company = val._id
        this.input.name = val.name
        this.input.address = _.get(val, 'address', {})
        this.resource.province.forEach(p => {
          if (p.text == this.input.address.province) {
            this.selectedProvince = p
          }
        })
        this.resource.regency.forEach(p => {
          if (p.text == this.input.address.regency) {
            this.selectedRegency = p
          }
        })
        this.resource.district.forEach(p => {
          if (p.text == this.input.address.district) {
            this.selectedDistrict = p
          }
        })
        this.resource.subdistrict.forEach(p => {
          if (p.text == this.input.address.subdistrict) {
            this.selectedSubdistrict = p
          }
        })
      } else {
        this.input.address.province = ''
        this.input.address.regency = ''
        this.input.address.district = ''
        this.input.address.subdistrict = ''
        this.selectedProvince = ''
        this.selectedRegency = ''
        this.selectedDistrict = ''
        this.selectedSubdistrict = ''
      }
    },
    selectedProvince: function(val) {
      if (val) {
        this.input.address.province = val.text
        this.selectedRegency = ''
        this.selectedDistrict = ''
        this.selectedSubdistrict = ''
        this.getRegency(val.value, this.input.address.regency)
      } else {
        this.input.address.province = ''
        this.selectedRegency = ''
        this.selectedDistrict = ''
        this.selectedSubdistrict = ''
      }
    },
    selectedRegency: function(val) {
      if (val) {
        this.input.address.regency = val.text
        this.selectedDistrict = ''
        this.selectedSubdistrict = ''
        this.getDistrict(val.value, this.input.address.district)
      } else {
        this.input.address.regency = ''
        this.selectedDistrict = ''
        this.selectedSubdistrict = ''
        this.getRegency(this.selectedProvince.value)
      }
    },
    selectedDistrict: function(val) {
      if (val) {
        this.input.address.district = val.text
        this.selectedSubdistrict = ''
        this.getSubdistrict(val.value, this.input.address.subdistrict)
      } else {
        this.input.address.district = ''
        this.selectedSubdistrict = ''
        this.getDistrict(this.selectedRegency.value)
      }
    },
    selectedSubdistrict: function(val) {
      if (val) {
        this.input.address.subdistrict = val.text
      } else {
        this.input.address.subdistrict = ''
        this.getSubdistrict(this.selectedDistrict.value)
      }
    }
  },
  async mounted() {
    await this.getHQCompany()
    await this.getProvince()
    if (this.id_company != '') {
      this.title = 'Ubah Data BMT'
      this.isNew = false
      this.getCompany()
    }
    this.input.timestamps.created_by = this.$store.getters.userLoggedIn.id
  },
  methods: {
    async getHQCompany() {
      this.resource.loadingCompany = true
      await axios.get(Constant.apiUrl.concat('/master/company?headquarters=1')).then(response => {
        const resData = response.data.data
        if (resData) this.resource.company = resData

        this.resource.loadingCompany = false
      })
    },

    async getProvince() {
      this.resource.loadingProvince = true
      await axios.get(Constant.apiUrl.concat('/region/province')).then(response => {
        const resData = response.data.data
        this.resource.province = resData.map(r => {
          return {
            text: r.name,
            value: r._id
          }
        })
        this.resource.loadingProvince = false
      })
    },
    async getRegency(id_province, name) {
      this.resource.loadingRegency = true
      await axios
        .get(
          Constant.apiUrl.concat('/region/regency?province=', id_province, '&search=', encodeURI(name || ''))
        )
        .then(response => {
          const resData = response.data.data
          this.resource.regency = resData.map(r => {
            return {
              text: r.name,
              value: r._id
            }
          })
          if (name)
            this.resource.regency.forEach(p => {
              if (p.text == this.input.address.regency) {
                this.selectedRegency = p
              }
            })
          this.resource.loadingRegency = false
        })
    },

    async getDistrict(id_regency, name) {
      this.resource.loadingDistrict = true
      await axios
        .get(
          Constant.apiUrl.concat('/region/district?regency=', id_regency, '&search=', encodeURI(name || ''))
        )
        .then(response => {
          const resData = response.data.data
          this.resource.district = resData.map(r => {
            return {
              text: r.name,
              value: r._id
            }
          })
          if (name)
            this.resource.district.forEach(p => {
              if (p.text == this.input.address.district) {
                this.selectedDistrict = p
              }
            })
          this.resource.loadingDistrict = false
        })
    },
    async getSubdistrict(id_district, name) {
      this.resource.loadingSubdistrict = true
      await axios
        .get(
          Constant.apiUrl.concat(
            '/region/subdistrict?district=',
            id_district,
            '&search=',
            encodeURI(name || '')
          )
        )
        .then(response => {
          const resData = response.data.data
          this.resource.subdistrict = resData.map(r => {
            return {
              text: r.name,
              value: r._id
            }
          })
          if (name)
            this.resource.subdistrict.forEach(p => {
              if (p.text == this.input.address.subdistrict) {
                this.selectedSubdistrict = p
              }
            })
          this.resource.loadingSubdistrict = false
        })
    },
    getCompany() {
      try {
        this.loading = true
        this.runApiCalls(() => {
          axios.get(Constant.apiUrl.concat('/master/company/', this.id_company)).then(response => {
            const company = response.data.data
            this.input = company
            this.input.isActive = company?.isActive || false
            this.isBranch = company.isHeadquarters ? '0' : '1'

            if (this.input.headquarters.id_company)
              this.selectedCompany = this.resource.company.filter(
                v => v._id == this.input.headquarters.id_company
              )[0]

            this.resource.province.forEach(p => {
              if (p.text == this.input.address.province) {
                this.selectedProvince = p
              }
            })
            this.loading = false
          })
        })
      } catch (error) {
        this.showFillError('Gagal mengambil data', error)
        this.loading = false
      }
    },
    saveCompany() {
      try {
        if (!this.id_company) {
          axios
            .post(Constant.apiUrl.concat('/master/company'), this.input)
            .then(response => {
              if (response.status === 200) {
                this.onCloseButtonClick()
                Swal.fire('Berhasil', 'Data BMT Berhasil Disimpan', 'success')
              }
            })
            .catch(error => {
              if (error.response) {
                const message = error.response.data.pesan
                Swal.fire({
                  icon: 'error',
                  title: 'Terjadi Kesalahan',
                  text: message
                })
              } else if (error.request) {
                Swal.fire({
                  icon: 'error',
                  title: 'Terjadi Kesalahan',
                  text: 'Tidak dapat menyambung ke server'
                })
              } else {
                // eslint-disable-next-line
                console.log('Something happened in the middle of request')
              }
            })
        } else {
          axios
            .put(Constant.apiUrl.concat('/master/company/', this.id_company), this.input)
            .then(response => {
              if (response.status === 200) {
                Swal.fire('Berhasil', 'Data Company Berhasil Perbarui', 'success')
                this.onCloseButtonClick()
              }
            })
            .catch(error => {
              if (error.response) {
                const message = error.response.data.pesan
                Swal.fire({
                  icon: 'error',
                  title: 'Terjadi Kesalahan',
                  text: message
                })
              } else if (error.request) {
                Swal.fire({
                  icon: 'error',
                  title: 'Terjadi Kesalahan',
                  text: 'Tidak dapat menyambung ke server'
                })
              } else {
                // eslint-disable-next-line
                console.log('Something happened in the middle of request')
              }
            })
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Terjadi Kesalahan',
          text: 'Gagal menyimpan BMT!',
          footer: '<a href>Please contact administrator</a>'
        })
      }
    },

    onCloseButtonClick() {
      this.$emit('close-form')
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-radio {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.detail-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.detail-input {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  &__label {
    flex: 1;
    display: flex;
    color: #222;
  }

  &__input-text {
    margin-left: 10px;
    max-width: 100%;
  }
  &__input-autocomplete {
    margin-left: 65px;
    max-width: 100%;
  }
}
input:placeholder-shown {
  font-size: 0.5em;
}
</style>
