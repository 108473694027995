<template>
  <v-card>
    <v-card-title class="justify-center">
      <span class="headline">{{ this.title }}</span>
      <v-btn absolute right fab @click="onCloseButtonClick()" small text color="primary">
        <v-icon dark>
          mdi-close-circle
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card-text>
        <v-container>
          <v-row no-gutters>
            <v-col md="10" offset-md="1">
              <v-row>
                <v-col md="5">
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="code">Kode</label>
                    </div>
                    <v-text-field
                      :readonly="!isAdmin"
                      id="code"
                      v-model="input.code"
                      dense
                      placeholder="Masukkan Kode Produk"
                      class="detail-input__input-text"
                      required
                      :loading="loading"
                    />
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="method">Pola Pembiayaan</label>
                    </div>
                    <v-text-field
                      :readonly="!isAdmin"
                      id="method"
                      v-model="input.payment_method"
                      dense
                      placeholder="Masukkan Pola Pembiayaan"
                      class="detail-input__input-text"
                      required
                      :loading="loading"
                    />
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="type">Jenis Jaminan</label>
                    </div>
                    <v-select
                      :readonly="!isAdmin"
                      id="type"
                      v-model="input.type"
                      dense
                      placeholder="Pilih Jenis Jaminan"
                      class="detail-input__input-text"
                      :items="resource.type"
                      :loading="loading"
                    ></v-select>
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="name">Nama</label>
                    </div>
                    <v-textarea
                      :readonly="!isAdmin"
                      v-model="input.name"
                      id="name"
                      class="detail-input__input-text align-start"
                      height="100"
                      outlined
                    />
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="description">Keterangan</label>
                    </div>
                    <v-textarea
                      :readonly="!isAdmin"
                      v-model="input.description"
                      id="description"
                      class="detail-input__input-text align-start"
                      height="100"
                      outlined
                    />
                  </div>
                </v-col>

                <v-col md="2"></v-col>
                <v-col md="5"
                  ><div class="detail-input">
                    <div class="detail-input__label">
                      <label for="duemin">Jangka Minimal</label>
                    </div>
                    <v-text-field
                      :readonly="!isAdmin"
                      id="duemin"
                      @keypress="onlyNumber"
                      v-model="input.due_min"
                      dense
                      suffix="Bulan"
                      placeholder="Masukkan angka"
                      class="detail-input__input-text"
                      required
                      :loading="loading"
                    />
                  </div>

                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="duemax">Jangka Maximal</label>
                    </div>
                    <v-text-field
                      :readonly="!isAdmin"
                      id="duemax"
                      @keypress="onlyNumber"
                      v-model="input.due_max"
                      dense
                      suffix="Bulan"
                      placeholder="Masukkan angka"
                      class="detail-input__input-text"
                      required
                      :loading="loading"
                    />
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="plafondmin">Plafond Minimal</label>
                    </div>
                    <v-currency-field
                      :readonly="!isAdmin"
                      id="plafondmin"
                      @keypress="onlyNumber"
                      v-model="input.plafond_min"
                      dense
                      prefix="Rp."
                      placeholder="Masukkan angka"
                      class="detail-input__input-text"
                      required
                      :loading="loading"
                    />
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="plafondmax">Plafond Maximal</label>
                    </div>
                    <v-currency-field
                      :readonly="!isAdmin"
                      id="plafondmax"
                      @keypress="onlyNumber"
                      v-model="input.plafond_max"
                      dense
                      prefix="Rp."
                      placeholder="Masukkan angka"
                      class="detail-input__input-text"
                      required
                      :loading="loading"
                    />
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="dueyear">Iuran</label>
                    </div>
                    <v-text-field
                      id="dueyear"
                      :readonly="!isAdmin"
                      @keypress="onlyNumber"
                      v-model="input.due_year"
                      dense
                      suffix="%"
                      placeholder="Masukkan Iuran per Bulan"
                      class="detail-input__input-text"
                      required
                      :loading="loading"
                    />
                  </div>
                  <div class="detail-radio" v-if="isAdmin">
                    <div class="detail-input__label">
                      <label for="status">Status</label>
                    </div>
                    <v-radio-group id="status" dense v-model="activeRadio" row>
                      <v-radio label="Aktif" value="yes"></v-radio>
                      <v-radio label="Tidak Aktif" value="no"></v-radio>
                    </v-radio-group>
                  </div>
                </v-col>
                <!-- <v-col md="10" offset-md="1" class="d-flex align-center">
                  <template>
                    <v-data-table
                      :headers="resource.dueHeader"
                      :items="input.due"
                      sort-by="calories"
                      hide-default-footer
                      class="table-kemasan"
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-toolbar-title>Iuran Per Bulan</v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>
                          <v-dialog v-model="dialog" max-width="800px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                                Tambah Data
                              </v-btn>
                            </template>
                            <v-card>
                              <v-card-title>
                                <span class="headline">Tambah Data Iuran</span>
                              </v-card-title>

                              <v-container>
                                <v-row align-content="center" justify="center">
                                  <v-col cols="12" class="d-flex justify-center">
                                    <v-text-field
                                      v-model="due.description"
                                      class="padding"
                                      dense
                                      label="Keterangan"
                                      placeholder=""
                                    />
                                    <v-text-field
                                      v-model="due.start"
                                      class="padding"
                                      dense
                                      label="Batas Bawah"
                                      suffix="Bulan"
                                      placeholder="0"
                                      @keypress="onlyNumber"
                                    />
                                    <v-text-field
                                      v-model="due.end"
                                      class="padding"
                                      dense
                                      label="Batas Atas"
                                      suffix="Bulan"
                                      placeholder="0"
                                      @keypress="onlyNumber"
                                    /><v-text-field
                                      v-model="due.value"
                                      class="padding"
                                      dense
                                      label="Nilai"
                                      suffix=" %"
                                      placeholder="0"
                                      @keypress="onlyNumber"
                                    />
                                  </v-col>
                                </v-row>
                              </v-container>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" @click="close" text>
                                  Batal
                                </v-btn>
                                <v-btn color="primary" @click="save" text>Simpan</v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-toolbar>
                      </template>
                      <template v-slot:[`item.actions`]="{ item }">
                        <v-icon small class="mr-2" @click="editItem(item)">
                          mdi-pencil
                        </v-icon>
                        <v-icon small @click="deleteItem(item)">
                          mdi-delete
                        </v-icon>
                      </template>
                    </v-data-table>
                  </template>
                </v-col> -->
                <v-col cols="12">
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" width="100" text large @click="onCloseButtonClick()">
                      Kembali
                    </v-btn>
                    <v-btn v-if="isAdmin" color="primary" width="100" @click="saveProduct()" large>
                      Simpan
                    </v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2'
import Constant from '@/const'

import ConstantMixin from '@/constantMixin'

const _ = require('lodash')
const axios = require('axios')

export default {
  mixins: [ConstantMixin],
  props: {
    id_product: String
  },
  data: () => ({
    title: 'Daftar Produk Baru',
    loading: false,
    valid: true,
    dialog: false,
    dialogDelete: false,
    activeRadio: '',
    isNew: true,
    isAdmin: false,
    input: {
      code: '',
      type: '',
      isActive: '',
      name: '',
      description: '',
      payment_method: '',
      due: [],
      due_year: 0,
      due_min: 0,
      due_max: 0,
      plafond_min: 0,
      plafond_max: 0,
      timestamps: {
        created_by: ''
      }
    },
    editedIndex: -1,
    due: {
      start: '',
      end: '',
      description: '',
      value: 0.0
    },
    resource: {
      dueHeader: [
        { text: 'Keterangan', value: 'description', align: 'center' },
        { text: 'Batas Awal', value: 'start', align: 'center' },
        { text: 'Batas Akhir', value: 'end', align: 'center' },
        { text: 'Nilai', value: 'value', align: 'center' },
        { text: '', value: 'actions' }
      ],
      type: ['kematian', 'kebakaran', 'kebakaran dan kematian']
    }
  }),
  watch: {},
  mounted() {
    if (_.toLower(this.$store.getters.userLoggedIn.role) == 'administrator') this.isAdmin = true
    if (this.id_product != '') {
      this.title = 'Ubah Data Produk'
      this.isNew = false
      this.getProduct()
    }
    this.input.timestamps.created_by = this.$store.getters.userLoggedIn.id
    this.getProduct()
  },
  methods: {
    getProduct() {
      try {
        this.loading = true
        this.runApiCalls(() => {})
        axios.get(Constant.apiUrl.concat('/product/', this.id_product)).then(response => {
          const product = response.data.data
          this.input.code = _.get(product, 'code', '')
          this.input.type = _.get(product, 'type', '')
          this.input.isActive = _.get(product, 'isActive', '')
          this.input.name = _.get(product, 'name', '')
          this.input.description = _.get(product, 'description', '')
          this.input.payment_method = _.get(product, 'payment_method', '')
          this.input.due_min = _.get(product, 'due_min', '')
          this.input.due_max = _.get(product, 'due_max', '')
          this.input.plafond_min = _.get(product, 'plafond_min', 0)
          this.input.plafond_max = _.get(product, 'plafond_max', 0)
          this.input.due_year = _.get(product, 'due_year', 0)
          this.input.due = _.get(product, 'due', [])

          this.activeRadio = this.input.isActive ? 'yes' : 'no'
          this.loading = false
        })
      } catch (error) {
        this.loading = false
      }
    },
    saveProduct() {
      try {
        this.input.isActive = this.activeRadio == 'yes' ? true : false
        if (!this.id_product) {
          axios
            .post(Constant.apiUrl.concat('/product'), this.input)
            .then(response => {
              if (response.status === 200) {
                this.onCloseButtonClick()
                Swal.fire('Berhasil', 'Data Product Berhasil Disimpan', 'success')
              }
            })
            .catch(error => {
              if (error.response) {
                const message = error.response.data.pesan
                Swal.fire({
                  icon: 'error',
                  title: 'Terjadi Kesalahan',
                  text: message
                })
              } else if (error.request) {
                Swal.fire({
                  icon: 'error',
                  title: 'Terjadi Kesalahan',
                  text: 'Tidak dapat menyambung ke server'
                })
              } else {
                // eslint-disable-next-line
                console.log('Something happened in the middle of request')
              }
            })
        } else {
          axios
            .put(Constant.apiUrl.concat('/product/', this.id_product), this.input)
            .then(response => {
              if (response.status === 200) {
                Swal.fire('Berhasil', 'Data Product Berhasil Perbarui', 'success')
                this.onCloseButtonClick()
              }
            })
            .catch(error => {
              if (error.response) {
                const message = error.response.data.pesan
                Swal.fire({
                  icon: 'error',
                  title: 'Terjadi Kesalahan',
                  text: message
                })
              } else if (error.request) {
                Swal.fire({
                  icon: 'error',
                  title: 'Terjadi Kesalahan',
                  text: 'Tidak dapat menyambung ke server'
                })
              } else {
                // eslint-disable-next-line
                console.log('Something happened in the middle of request')
              }
            })
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Terjadi Kesalahan',
          text: 'Gagal menyimpan product!' + error,
          footer: '<a href>Please contact administrator</a>'
        })
      }
    },
    onCloseButtonClick() {
      this.$emit('close-form')
    },
    close() {
      this.dialog = false
      setTimeout(() => {
        this.due = Object.assign(
          {},
          {
            start: '',
            end: '',
            description: '',
            value: 0.0
          }
        )
        this.editedIndex = -1
      }, 300)
    },
    editItem(item) {
      this.editedIndex = this.input.due.indexOf(item)
      this.due = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      const index = this.input.due.indexOf(item)
      this.input.due.splice(index, 1)
    },
    save() {
      if (
        this.due.description !== '' &&
        this.due.start !== '' &&
        this.due.end !== '' &&
        this.due.value !== ''
      ) {
        if (this.editedIndex > -1) {
          Object.assign(this.input.due[this.editedIndex], this.due)
        } else {
          this.input.due.push(this.due)
        }
        this.close()
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Terjadi Kesalahan',
          text: 'Data belum sesuai',
          footer: '<a href>Harap lengkapi data dahulu</a>'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-radio {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.detail-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.detail-input {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  &__label {
    flex: 1;
    display: flex;
    color: #222;
  }

  &__input-text {
    margin-left: 10px;
    max-width: 60%;
  }
}
.table-kemasan {
  width: 100%;
  margin-top: 20px;
}
.padding {
  padding-left: 10px;
}

.margin-top {
  margin-top: 20px;
}

input:placeholder-shown {
  font-size: 0.5em;
}
</style>
