<template>
  <v-card>
    <v-card-title class="justify-center">
      <span class="headline">{{ this.title }}</span>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="isFormValid">
        <v-row no-gutters>
          <v-col md="10" offset-md="1">
            <v-row>
              <v-col md="12">
                <div v-if="account" class="detail-input">
                  <div class="detail-input__label">
                    <label for="ap">Akun</label>
                  </div>
                  <v-text-field
                    id="ap"
                    v-model="accountParent"
                    dense
                    class="detail-input__input-text"
                    disabled
                    :isLoading="isLoading"
                  />
                </div>
                <div v-else class="detail-input">
                  <div class="detail-input__label">
                    <label for="ka">Kode Akun</label>
                  </div>
                  <v-text-field
                    id="ka"
                    v-model="input.code"
                    dense
                    class="detail-input__input-text"
                    disabled
                    :isLoading="isLoading"
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="name">{{ account ? 'Nama Sub Akun' : 'Nama Akun' }}</label>
                  </div>
                  <v-text-field
                    id="name"
                    v-model="input.name"
                    dense
                    class="detail-input__input-text"
                    :isLoading="isLoading"
                    :rules="[v => !!v || 'Harus di Isi.']"
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="isDebit">Akun Debit/Kredit</label>
                  </div>
                  <v-select
                    id="isDebit"
                    :items="resource.isDebit"
                    v-model="input.isDebit"
                    item-text="text"
                    item-value="value"
                    dense
                    class="detail-input__input-text"
                    :isLoading="isLoading"
                  />
                </div>
              </v-col>

              <v-col cols="12">
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" width="100" text large @click="onCloseButtonClick()">
                    Kembali
                  </v-btn>
                  <v-btn color="primary" width="100" @click="saveChartAccount()" large>
                    Simpan
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2'
import Constant from '@/const'

import ConstantMixin from '@/constantMixin'

const _ = require('lodash')
const moment = require('moment')
const axios = require('axios')
export default {
  name: 'ChartAccountComponent',
  mixins: [ConstantMixin],
  props: {
    account: Object
  },
  data: () => ({
    title: 'Tambah sub Akun',
    isFormValid: false,
    isLoading: false,
    dialog: false,
    tagSearch: null,
    accountParent: '',
    input: {
      id_parent: null,
      parentCode: '',
      name: '',
      isDebit: true,
      timestamps: {
        created_by: ''
      }
    },
    resource: {
      isDebit: [
        {
          text: 'Debit',
          value: true
        },
        {
          text: 'Kredit',
          value: false
        }
      ],
      tag: []
    }
  }),
  watch: {},
  mounted() {
    this.$nextTick(() => {
      if (!this.account) {
        this.title = 'Tambah Akun Baru'
        this.getLastAccount()
      } else {
        this.accountParent = `${this.account.code} - ${this.account.name}`
        this.input.id_parent = this.account._id
        this.input.parentCode = this.account.code
        this.input.isDebit = this.account.isDebit
      }

      this.input.timestamps.created_by = this.$store.getters.userLoggedIn.id
    })
  },

  methods: {
    getLastAccount() {
      try {
        this.isLoading = true

        this.runApiCalls(() => {
          axios.get(Constant.apiUrl.concat('/accounting/chartaccount/generate')).then(response => {
            const { data } = response.data
            this.input.code = data.code
            this.isLoading = false
          })
        })
      } catch (error) {
        this.isLoading = false
      }
    },
    saveChartAccount() {
      try {
        axios
          .post(Constant.apiUrl.concat('/accounting/chartaccount'), this.input)
          .then(response => {
            if (response.status === 200) {
              this.onCloseButtonClick()
              this.swalToast('success', 'Data Akun Berhasil Disimpan')
            }
          })
          .catch(error => {
            if (error.response) {
              const message = error.response.data.pesan
              Swal.fire('Terjadi Kesalahan', message, 'error')
            } else if (error.request) {
              Swal.fire('Terjadi Kesalahan', 'Tidak dapat menyambung ke server', 'error')
            } else {
              // eslint-disable-next-line
              // console.log('Something happened in the middle of request')
            }
          })
      } catch (error) {
        // console.log(error)
        Swal.fire({
          icon: 'error',
          title: 'Terjadi Kesalahan',
          text: 'Gagal menyimpan account!'
        })
      }
    },
    onCloseButtonClick() {
      this.$emit('close-form')
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-radio {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.detail-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.detail-input {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  &__label {
    flex: 1;
    display: flex;
  }

  &__input-text {
    margin-left: 10px;
    max-width: 60%;
  }
}
input:placeholder-shown {
  font-size: 0.5em;
}
</style>
