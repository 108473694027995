<template>
  <main class="main">
    <transition name="journal-view">
      <v-container id="journal-view" fluid tag="section">
        <div class="search mt-n3 pb-3">
          <v-btn dark small color="primary" @click="openDialog(0, '')">
            <v-icon>mdi-plus</v-icon>
          </v-btn>

          <v-spacer></v-spacer>
          <v-btn class="mx-2" @click="getAllJournal()" fab small color="primary">
            <v-icon> mdi-autorenew </v-icon>
          </v-btn>
          <v-text-field
            v-model="table.search"
            prepend-icon="mdi-magnify"
            class="input"
            label="Cari Jurnal..."
            single-line
            hide-details
          ></v-text-field>
        </div>

        <div class="datatable">
          <v-data-table
            :headers="table.headers"
            :items="journals"
            hide-default-footer
            :loading="isLoading"
            :search="table.serach"
            :page.sync="pagination.page"
            :items-per-page="pagination.rowsPerPage"
            item-key="name"
            class="elevation-1"
            dense
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-menu bottom origin="center center" transition="scale-transition">
                <template v-slot:activator="{ on: menu, attrs }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn icon color="primary" dark v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                        <v-icon dark> mdi-menu </v-icon>
                      </v-btn>
                    </template>
                    <span>Menu</span>
                  </v-tooltip>
                </template>
                <v-list dense>
                  <v-list-item @click="openDialog(0, item._id)">
                    <v-list-item-icon>
                      <v-icon>mdi-pencil</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Detail Jurnal</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </div>
        <div class="datatable-action">
          <v-pagination
            v-model="pagination.page"
            :length="journalsPage"
            :total-visible="5"
            @input="getAllJournal()"
          ></v-pagination>
        </div>
      </v-container>
    </transition>
    <v-dialog v-model="isDialog" fullscreen>
      <journal-input
        v-if="selectedForm == 0"
        :id_journal="selectedItem"
        @change-form="changeForm"
        @close-form="closeForm"
      />
    </v-dialog>
  </main>
</template>

<script>
import Constant from '@/const'

import ConstantMixin from '@/constantMixin'
import JournalInput from '../components/accounting/JournalInput.vue'

const _ = require('lodash')
const moment = require('moment')
const axios = require('axios')

export default {
  name: 'Journal',
  components: { JournalInput },
  mixins: [ConstantMixin],
  data() {
    return {
      isLoading: false,
      isDialog: false,

      selectedForm: '',
      selectedItem: null,
      table: {
        search: '',
        headers: [
          {
            text: 'Jurnal',
            value: 'code',
            width: '150px',
            sortable: false,
            class: 'text-subtitle-1 font-weight-black'
          },
          {
            text: 'Tanggal',
            value: 'journal_date',
            width: '150px',
            sortable: false,
            class: 'text-subtitle-1 font-weight-black'
          },
          {
            text: 'Jam',
            value: 'journal_time',
            width: '80px',
            sortable: false,
            class: 'text-subtitle-1 font-weight-black'
          },
          {
            text: 'Nominal',
            value: 'nominal',
            width: '90px',
            sortable: false,
            class: 'text-subtitle-1 font-weight-black'
          },
          {
            text: 'Status',
            value: 'status',
            width: '80px',
            sortable: false,
            align: 'center',
            class: 'text-subtitle-1 font-weight-black'
          },
          {
            text: '',
            align: 'center',
            value: 'actions',
            width: '50px',
            sortable: false,
            class: 'text-subtitle-1 font-weight-black'
          }
        ]
      }
    }
  },
  watch: {
    isDialog(newValue) {
      if (!newValue) {
        this.selectedForm = null
      }
    },
    'table.search'(val) {
      this.pagination.page = 1
      setTimeout(this.getAllJournal(), 300)
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getAllJournal()
    })
  },
  methods: {
    getAllJournal(option) {
      try {
        this.isLoading = true
        this.runApiCalls(() => {
          axios
            .get(
              Constant.apiUrl.concat(
                '/accounting/journal?page=',
                this.pagination.page,
                '&itemCount=',
                this.pagination.rowsPerPage,
                '&search=',
                this.table.search
              )
            )
            .then(response => {
              if (response.status == 200) {
                const { data, length } = response.data
                this.journalsPage = Math.ceil(length / this.pagination.rowsPerPage)
                this.journals = data.map(j => {
                  return {
                    ...j,
                    journal_date: moment(j.journal_date)
                      .locale('id')
                      .format('DD MMMM YYYY'),
                    journal_time: moment(j.journal_date)
                      .locale('id')
                      .format('HH:mm'),
                    nominal: this.getCurrency(j.nominal),
                    status: j.status.toUpperCase()
                  }
                })
              }
              this.isLoading = false
            })
            .catch(error => {
              this.isLoading = false
              // console.log(error)
            })
        })
      } catch {
        this.isLoading = false
      }
    },
    deleteJournal(id_journal) {},
    openDialog(index, id_journal) {
      this.selectedItem = id_journal
      switch (index) {
        default:
          this.changeForm(index)
          break
      }
      this.isDialog = true
    },
    changeForm(item) {
      this.selectedForm = item
    },
    closeForm() {
      this.isDialog = false
      this.getAllJournal()
    }
  }
}
</script>

<style lang="scss" scoped>
.search {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  right: 0;
}
.input {
  max-width: 30%;
}
.datatable {
  height: 500px;
}
.main {
  width: 100%;
  flex-direction: column;
  position: relative;
  height: 95%;

  width: 90%;
  overflow-y: off;
  margin: 10px auto;
}
.datatable-action {
  position: absolute;
  left: 10px;
  bottom: -8vh;
}
.btn-new {
  right: 50px;
  bottom: 1vh;
}
</style>
