<template>
  <v-card>
    <v-card-title class="justify-center">
      <span class="headline">{{ this.title }}</span>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="isFormValid">
        <v-row no-gutters>
          <v-col md="10" offset-md="1">
            <v-row>
              <v-col md="12">
                <div class="detail-input" v-if="resource.listAccountHelper.length">
                  <div class="detail-input__label">
                    <label for="helper">Akun Pembantu</label>
                  </div>
                  <v-autocomplete
                    id="helper"
                    v-model="input.id_accountHelper"
                    :items="resource.listAccountHelper"
                    :item-text="item => `${item.name} (${getCurrency(item.nominal)})`"
                    item-value="_id"
                    dense
                    class="detail-input__input-text"
                    :isLoading="isLoading"
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="nominal">Nominal</label>
                  </div>
                  <v-currency-field
                    id="nominal"
                    v-model="input.nominal"
                    dense
                    class="detail-input__input-text"
                    :isLoading="isLoading"
                    :rules="[v => !!v || 'Harus di Isi.']"
                  />
                </div>
              </v-col>

              <v-col cols="12">
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" width="100" text large @click="onCloseButtonClick()">
                    Kembali
                  </v-btn>
                  <v-btn color="primary" width="100" @click="saveInitialBalance()" large>
                    Simpan
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2'
import Constant from '@/const'

import constantMixin from '@/constantMixin'

const _ = require('lodash')
const axios = require('axios')

export default {
  name: 'LedgerInitialBalance',
  mixins: [constantMixin],
  props: {
    account: Object
  },
  data: () => ({
    title: 'Masukkan Saldo Awal',
    isFormValid: false,
    isLoading: false,
    dialog: false,
    tagSearch: null,
    accountParent: '',
    input: {
      id_account: '',
      id_accountHelper: '',
      nominal: 0
    },
    resource: {
      listAccountHelper: []
    }
  }),
  watch: {
    input: {
      handler() {
        this.$nextTick(() => {})
      },
      deep: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.input.id_account = this.account.value
      this.getAccountHelper()
    })
  },

  methods: {
    getAccountHelper() {
      this.runApiCalls(() => {
        axios
          .get(Constant.apiUrl.concat('/accounting/chartaccounthelper?account=', this.account.value))
          .then(response => {
            if (response.status == '200') {
              this.resource.listAccountHelper = response.data.data
            }
            this.isLoading = false
          })
      })
    },
    getChartAccount() {
      try {
        this.isLoading = true

        this.runApiCalls(() => {
          axios.get(Constant.apiUrl.concat('/accounting/chartaccount')).then(response => {
            if (response.status == '200') {
              this.resource.account = response.data.data
                .map(d => {
                  return {
                    ...d,
                    splitCode: d.code.split('.'),
                    displayName: `${d.code} - ${d.name}`
                  }
                })
                .filter(v => v.splitCode[2] != 0)
            }
            this.isLoading = false
          })
        })
      } catch (error) {
        this.isLoading = false
      }
    },
    saveInitialBalance() {
      try {
        if (this.resource.listAccountHelper.length > 0 && !this.input.id_accountHelper)
          this.swalToast('warning', 'Akun Pembantu harus dipilih')

        axios
          .post(Constant.apiUrl.concat('/accounting/chartaccount/initialbalance/'), this.input)
          .then(response => {
            if (response.status === 200) {
              this.onCloseButtonClick(true)
              this.swalToast('success', 'Saldo Awal Berhasil disimpan', 2000)
            }
          })
          .catch(error => {
            if (error.response) {
              const message = error.response.data.pesan
              Swal.fire('Oops...', message, 'error')
            } else if (error.request) {
              Swal.fire('Oops...', 'Tidak dapat menyambung ke server', 'error')
            } else {
              // eslint-disable-next-line
              // console.log('Something happened in the middle of request')
            }
          })
      } catch (error) {
        // console.log(error)
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Gagal menyimpan account!'
        })
      }
    },
    onCloseButtonClick(isSaved = false) {
      this.$emit('close-form', isSaved)
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-radio {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.detail-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.detail-input {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  &__label {
    flex: 1;
    display: flex;
  }

  &__input-text {
    margin-left: 10px;
    max-width: 60%;
  }
}
input:placeholder-shown {
  font-size: 0.5em;
}
</style>
